<template>
  <HeaderComponent></HeaderComponent>
  <component :is="currentView"/>
  <FooterComponent></FooterComponent>
</template>

<script>

import HeaderComponent from "@/components/HeaderComponent";
import FooterComponent from "@/components/FooterComponent";
import Home from "@/pages/Home";
import Contact from "@/pages/Contact";
import PageNotFound from "@/pages/PageNotFound";
import Gallery from "@/pages/Gallery";
import Vue3Samples from "@/samples/Vue3Samples";
import EBooks from "@/pages/EBooks.vue";
import {page} from 'vue3-analytics'

const routes = {
  '/': Home,
  '/contact': Contact,
  '/gallery': Gallery,
  '/ebooks': EBooks,
  '/vue3': Vue3Samples
}

export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent
  },
  data() {
    return {
      currentPath: window.location.hash
    }
  },
  computed: {
    currentView() {
      return routes[this.currentPath.slice(1) || '/'] || PageNotFound
    }
  },
  mounted() {
    window.addEventListener('hashchange', () => {
      this.currentPath = window.location.hash
    })
  },
  methods: {
    track() {
      page('/')
    }
  }
}
</script>

<style>
body{
  background-color: black;
  color: gray;
}

@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url("assets/fonts/Jost/600.ttf");
}

#app {
  font-family: Jost;
}
</style>
