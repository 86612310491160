<template>
  <center> <a href="../assets/ebooks/arcgis-server-high-capacity.pdf" download>ArcGIS Server High Capacity</a></center>
  <center> <a href="../assets/ebooks/creating-arcgisserver-web-mapping.pdf" download>ArcGIS Server Web Mapping</a></center>
</template>

<script>
export default {
  name: "EBooks"
}
</script>

<style scoped>

</style>