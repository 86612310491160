<template>
  <div class="container">
    <br>
    <ul>
      <li>FECC</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Vue3Samples"
}
</script>

<style scoped>

</style>