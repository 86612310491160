<script>


export default {
  name: "ContactPage",

}
</script>
<template>
  <div class="container">

    <br>
    <br>
    <br>
    <div class="row">
      <div class="col-12">
        <form action="https://api.web3forms.com/submit" method="POST">
          <input type="hidden" class="form-control" name="access_key" value="0d75608b-66f4-4d6d-ad77-4c36053086da">
          <input type="text" class="form-control" name="name" placeholder="Name Surname " required>
          <input type="email" class="form-control" name="email" placeholder="Email" required>
          <textarea v-model="text" name="message" class="form-control" required></textarea>
          <input type="hidden" name="redirect" value="https://web3forms.com/success">
          <button class="btn btn-secondary form-control" type="submit">Send</button>
        </form>
        <div class="row"><br></div>
        <div class="row">
          <div style="text-align: center">
            turkerakbulut@gmail.com
          </div>
        </div>
      </div>
    </div>
  </div>

</template>



<style scoped>

</style>