<template>
  <nav class="navbar navbar-expand-sm navbar-light bg-light" aria-label="Third navbar example">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">Türker Akbulut</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample03"
              aria-controls="navbarsExample03" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarsExample03">
        <ul class="navbar-nav ms-auto mb-2 mb-sm-0">
          <!--          <li class="nav-item dropdown">-->
          <!--            <a class="nav-link dropdown-toggle" href="#" id="dropdown03" data-bs-toggle="dropdown" aria-expanded="false">Samples</a>-->
          <!--            <ul class="dropdown-menu" aria-labelledby="dropdown03">-->
          <!--              <li><a class="dropdown-item" href="#/vue3">Vue3</a></li>-->
          <!--              <li><a class="dropdown-item" href="#">ESRI ArcObjects</a></li>-->
          <!--              <li><a class="dropdown-item" href="#">OpenCV</a></li>-->
          <!--              <li><a class="dropdown-item" href="#">Pyhton</a></li>-->
          <!--            </ul>-->
          <!--          </li>-->
          <li class="nav-item">
            <a class="nav-link" href="#/">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#/ebooks">EBooks</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#/gallery">Gallery</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#/contact">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "HeaderComponent"
}
</script>

<style scoped>

</style>