<script>
export default {
  name: "DigitalClock",
  data() {
    return {
      year: 0,
      month :0,
      day : 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  },
  mounted() {
    setInterval(() => this.setTime(), 1000)
  },
  methods: {
    getMonthName(index)
    {
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      return monthNames[index];
    },
    setTime() {
      const date = new Date();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();
      hours = hours <= 9 ? `${hours}`.padStart(2, 0) : hours;
      minutes = minutes <= 9 ? `${minutes}`.padStart(2, 0) : minutes;
      seconds = seconds <= 9 ? `${seconds}`.padStart(2, 0) : seconds;
      this.year = date.getFullYear();
      this.month=this.getMonthName(date.getMonth());
      this.day=date.getDate();
      this.hours = hours;
      this.minutes = minutes;
      this.seconds = seconds;
    }
  }
}
</script>

<template>
  <div class="container">
    <div class="LCD">
      <div>{{ year }}</div>
      <div>&nbsp;</div>
      <div>{{ month }}</div>
      <div>&nbsp;</div>
      <div>{{ day }}</div>
      <div>&nbsp;&nbsp;</div>
      <div class="hours">{{ hours }}</div>
      <div class="divider">:</div>
      <div class="minutes">{{ minutes }}</div>
      <div class="divider">:</div>
      <div class="seconds">{{ seconds }}</div>
    </div>
  </div>
</template>

<style scoped>
.LCD {
  display: flex;
}
.LCD > div {
  font-size: medium;
}
</style>