<template>
  <div>
    <iframe  src="https://mars.nasa.gov/embed/24461/" width='100%' height="600">
    </iframe>
<!--    <iframe src="https://mars.jpl.nasa.gov/layout/embed/image/640/?i=5784"
            width="100%" height="400">

    </iframe>

    <div>{{image}} {{index}}</div>-->


  </div>
</template>
<script>
export default {
  name: "GalleryPage",
  data() {
    return {
      index : 2400,
      image : "https://mars.nasa.gov/embed/24000/"
    }
  },
  /*mounted() {
    setInterval(() => this.setTime(), 60000)
  },
  methods: {
    setTime() {
      this.index = this.index+1;
      this.image ='https://mars.nasa.gov/embed/24000/';
      //this.image = "https://mars.nasa.gov/embed/" + this.index.toString() +"/";
    }
  }*/

}
</script>
<style scoped>
</style>