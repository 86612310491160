<template>
  <div class="container">
    <div class="row">
      <div data-aos="zoom-in"
           data-aos-easing="linear"
           data-aos-duration="1500" style="text-align: center">
        <DigitalClock/>
      </div>
    </div>
    <br>
    <div class="row">
      <div data-aos="zoom-in"
           data-aos-easing="lack"
           data-aos-duration="1500" style="text-align: end">
        “The person who writes for fools is always sure of a large audience.”
        <div>
          <span style="font-size: smaller">Arthur Schopenhauer, Religion: A Dialogue and Other Essays</span>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div data-aos="zoom-out"
           data-aos-easing="lack"
           data-aos-duration="1500" style="text-align:left">
        “Life is a constant process of dying.”
        <div>
          <span style="font-size: smaller">Arthur Schopenhauer</span>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div data-aos="fade-down"
           data-aos-easing="linear"
           data-aos-duration="1500"
           style="text-align:center">
        “We forfeit three-fourths of ourselves in order to be like other people.”
        <div>
          <span style="font-size: smaller">Arthur Schopenhauer</span>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div data-aos="fade-up"
           data-aos-easing="linear"
           data-aos-duration="1500"
           style="text-align:left">
        “Hope is the confusion of the desire for a thing with its probability. ”
        <div>
          <span style="font-size: smaller">Arthur Schopenhauer, Essays and Aphorisms</span>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div data-aos="fade-up"
           data-aos-easing="linear"
           data-aos-duration="1500"
           style="text-align:end">
        “The safest way of not being very miserable is not to expect to be very happy.”
        <div>
          <span style="font-size: smaller">Arthur Schopenhauer</span>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div data-aos="fade-left"
           data-aos-easing="linear"
           data-aos-duration="1500"
           style="text-align:left">
        “We seldom think of what we have, but always of what we lack.”
        <div>
          <span style="font-size: smaller">Arthur Schopenhauer</span>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div data-aos="fade-down"
           data-aos-easing="linear"
           data-aos-duration="1500"
           style="text-align:center">
        “To attain something desired is to discover how vain it is; and…though we live all our lives in expectation of
        better things, we often at the same time long regretfully for what is past. The present, on the other hand, is
        regarded as something quite temporary and serving only as the road to our goal. That is why most men discover
        when they look back on their life that they have the whole time been living ad interim, and are surprised to see
        that which they let go by so unregarded and unenjoyed was precisely their life, was precisely in expectation of
        which they lived.”
        <div>
          <span style="font-size: smaller">Arthur Schopenhauer</span>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div data-aos="fade-down"
           data-aos-easing="linear"
           data-aos-duration="1500"
           style="text-align:center;font-size: large">
        “Hiçlik ile şey arasındasın, hiçliğe daha yakın.”
        <div>
          <span style="font-size: large">...</span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import 'aos/dist/aos.css';
import AOS from 'aos';
import DigitalClock from "@/components/DigitalClock.vue";
export default {
  name: "HomePage",
  components: {
    DigitalClock
  },
  mounted() {
    AOS.init()
  }
}
</script>

<style scoped>

</style>